import { BlogPostEntity, Maybe } from "@/gql/generated/graphql";
import React, { Fragment } from "react";
import ResponsiveImage from "./ResponsiveImage";
import Link from "next/link";
// import { readingTime } from '@/lib/ReadingTime'

type Props = {
  article?: Maybe<BlogPostEntity>;
  size?: string;
};

const ArticleCardItem = (props: Props) => {
  const categories = props.article?.attributes?.blog_categories?.data;
  return (
    <>
      <div className="img-box relative pb-[56%] shadow-b">
        <Link href={`/${props.article?.attributes?.slug ?? "#"}`}>
          <ResponsiveImage
            size={props.size ?? "30vw"}
            className="absolute inset-0 object-cover w-full h-full"
            image={props.article?.attributes?.featureImage}
          />
        </Link>
      </div>
      <h6 className="font-normal text-sm uppercase text-[#252525] mt-9">
        {categories &&
          categories.map((cate, index) => (
            <Fragment key={index}>
              <Link href={`/${cate.attributes?.slug}`}>
                {cate.attributes?.title}
              </Link>
              {`${categories.length - 1 == index ? "" : ", "}`}
            </Fragment>
          ))}
      </h6>
      <Link
        className="font-josefin font-semibold text-xl sm:text-2xl text-[#252525] line-clamp-2 mb-4 group-hover:text-[#252525]"
        href={`/${props.article?.attributes?.slug}`}
      >
        {props.article?.attributes?.title}
      </Link>
      <Link
        href={`/${props.article?.attributes?.slug}`}
        className="text-sm font-normal text-link-hover underline hover:text-[#1D4F30] group-hover:no-underline"
      >
        Read Article
      </Link>
    </>
  );
};

export default ArticleCardItem;
