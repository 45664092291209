"use client";
import { SITE_URL } from "@/env";
import { usePathname, useRouter } from "next/navigation";

const ArticleSocialShare = ({ title }: { title: string }) => {
  const pathname = usePathname();

  const href = SITE_URL + pathname;

  return (
    <ul className="social-wrap flex flex-wrap mb-5">
      <li className="faceboox">
        <a
          href={`https://facebook.com/sharer/sharer.php?u=${href}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Faceboox
        </a>
      </li>
      <li className="twitter">
        <a
          href={`https://twitter.com/intent/tweet?text=${href}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          X
        </a>
      </li>{" "}
      <li className="reddit">
        <a
          href={`https://reddit.com/submit?url=${href}&title=${title}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Reddit
        </a>
      </li>
      <li className="linkedin">
        <a
          href={`https://www.linkedin.com/shareArticle?mini=true&url=${href}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          LinkedIn
        </a>
      </li>
      <li className="mail">
        <a
          href={`mailto:?subject=Interesting Read&body=${title}: ${href}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Mail
        </a>
      </li>
    </ul>
  );
};
export default ArticleSocialShare;
