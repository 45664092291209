"use client";
import { useEffect, useState } from "react";
import { getHeaderAnchors, useTocHighlight } from "./useTocHighlight";
import ArticleSocialShare from "./ArticleSocialShare";

function ArticleOutlineWrapper({ title }: { title: string }) {
  const [headings, setHeadings] = useState<HTMLAnchorElement[]>([]);
  useEffect(() => {
    const headings = getHeaderAnchors();

    if (headings) {
      setHeadings(headings);
    }
  }, []);
  return <ArticleOutline title={title} headings={headings} />;
}

const ArticleOutline = ({
  headings,
  title,
}: {
  headings: HTMLAnchorElement[] | [];
  title: string;
}) => {
  const { currentIndex } = useTocHighlight();
  const selectedIndex = Math.min(currentIndex, headings.length - 1);
  return headings && headings.length > 0 ? (
    <div className="left-side-content-menu flex flex-col items-start text-left">
      <div className="sticky left-0 top-24 bottom-auto pl-10 pr-6 py-4 ">
        <ol
          style={{
            scrollbarWidth: "thin",
            scrollbarColor: "#e4e7ec transparent",
            // scrollbarColor: "#0c671a transparent",
          }}
          className="max-h-[480px] overflow-y-auto pr-4"
          // className="max-h-[480px] overflow-y-auto px-4 py-2 bg-[#def1cc66]"
        >
          {headings.map((headerVal, index) => (
            <li
              key={index}
              className={`${selectedIndex === index ? "active" : ""}`}
            >
              <a
                href={`#${headerVal.id}`}
                className={`${"font-albert font-normal text-base text-[#263D42] no-underline hover:text-[#0C671A] hover:underline"}`}
              >
                {headerVal.innerText}
              </a>
            </li>
          ))}
        </ol>
        <h6 className="font-albert font-normal text-base text-[#151617] block mt-10">
          Share:
        </h6>
        <ArticleSocialShare title={title} />
      </div>
    </div>
  ) : null;
};

export default ArticleOutlineWrapper;
